@import '../../../../styles/customMediaQueries.css';

/* Desktop */
.root {
  /* Size */
  width: 100%;
  max-width: 1128px;
  height: var(--topbarHeightDesktop);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 18px 0 0 0;

  @media (max-width: 1170px) {
    padding: 18px 10px 0 24px;
  }
}

/* logo */
.logoLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);
}

/* Search */
.searchLink {
  min-width: 450px;
  height: 100%;
  border: 1px solid var(--colorBorderSecondary);
  box-shadow: var(--blockShadow);
  border-radius: 32px;
  display: flex;
  align-items: center;
}

.search {
  font-weight: var(--fontWeightMedium);
  font-size: var(--fontSizeSmall);
  line-height: 24px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
  }
}

.takeAvailableSpace {
  flex-grow: 1;

  /* At 1024px, the translations might need more space than what searchLink otherwise gets. */
  min-width: 220px;
}

.topbarSearchWithLeftPadding {
  padding-left: 24px;
  padding-right: 0;
  height: 52px;

  @media (--viewportLarge) {
    padding-left: 15px;
    padding-right: 11px;
    width: 100%;
  }
}

/* These is used with Inbox, Sign up, and Log in */
.topbarLink {
  flex-shrink: 0;

  border-bottom: 3px solid transparent; /* Initial transparent border */
  transition: border-color var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: var(--fontSizeSmall);
  line-height: 18px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  justify-content: center;
  align-items: center;

  height: 100%;
  &:hover {
    color: var(--colorSecondary);
    border-radius: 0;
    text-decoration: none;
    padding-top: 0px;
    
  }
  
  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.borderBottom:hover {
  border-bottom-color: var(--colorSecondary);
}

.joinNow {
  color: var(--colorPrimary);
  padding: 10px 24px;
  border-radius: 4px;
  transition: ease .3s;
  width: 100%;

  &:hover {
    color: var(--colorBlack);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }
}

.joinNow:hover{
  color: var(--colorSecondary);
}

.topbarLinkLabel {
  color: var(--colorPrimary);
}

.topbarLinkLabel:hover {
  color: var(--colorSecondary);
}

.pricingLink {
  composes: webCTA18px from global;
}

.topbarLinkLabel,
.joinNow {
  composes: textSmall from global;
  display: inline-block;
  margin: 0;
  text-decoration: inherit;
  position: relative;
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 2px;
  right: -9px;

  /* Style: red dot */
  background-color: var(--colorFail);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  composes: profileMenuLabel from global;
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.profileMenuContent {
  min-width: 276px;
  padding: 20px 0;
  margin-top: 8px;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.menuLink {
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  font-weight: var(--fontWeightMedium);
  font-size: var(--textPriceRegular);
  line-height: 20px;
  color: var(--colorPrimary);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorSecondary);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  display: inline;

  /* Text size should be inherited */
  text-decoration: none;

  /* Font is specific to this component */
  font-weight: var(--fontWeightMedium);
  font-size: var(--textPriceRegular);
  line-height: 20px;
  letter-spacing: 0;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 15px 24px;

  /* Layout details */
  color: var(--colorSecondary);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);

  /* Borders */
  border: none;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--colorGrey100);

  &:hover {
    cursor: pointer;
    color: var(--colorBlack);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin-top: 15px;
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--colorBlack);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--colorBlack);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--colorBlack);
    }
  }
}

.topBarProfile {
  display: flex;
  align-items: center;
  gap: 32px;
  margin-right: 0;
  margin-top: 1px;
  
  @media (max-width: 1170px) {
    margin-right: 9px;
  }
}

.marginLogin {
}

.avatar {
  background-image: url('/src/assets/Avatar.png');
}

.profileMenuLabel {
  composes: profileMenuLabel from global;
}

.login {
  width: 100%;
  padding: 10px 24px;
  border-top: 1px solid #F2F2F7;
  margin-top: 10px;

  &:hover {
    color: var(--colorSecondary);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }
}

.buyer {
  /* Borders */
  border: none;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--colorGrey100);
  margin-top: 15px;
  padding-top: 11px;
}