@import '../../../../../styles/customMediaQueries.css';

.priorityLinkWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-right: 7px;
  margin-left: 67px;
  padding-top: 7px;
}

.priorityLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: var(--fontSizeSmall);
  line-height: 18px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  margin: 0;

  &:hover {
    color: var(--colorSecondary);
    border-bottom: 4px solid var(--colorSecondary);
    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0 25px 0 0;
  }
}

.highlight {
  color: var(--marketplaceColor);
  &:hover {
    color: var(--colorSecondary);
  }
}

.priorityLinkLabel {
  display: none;
  composes: webCTA18px from global;
  display: inline-block;
  margin: 13px 0;
  text-decoration: inherit;
  text-wrap: nowrap;
  color: var(--colorPrimary);
}

.priorityLinkLabel:hover {
  color: var(--colorSecondary);
}

.postNewList {
  display: none;
}
